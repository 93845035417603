import React from 'react';
import { NativeBaseProvider } from 'native-base';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PowerPurchase from './pages/PowerPurchase';
import ServiceFee from './pages/ServiceFee';
import Login from './pages/Login';
import Home from './pages/Home';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import About from './pages/About';
import Contact from './pages/Contact';

const App: React.FC = () => {
  return (
    <NativeBaseProvider>
      <AuthProvider>
        <Router>
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<About />} />
            <Route path='/contact' element={<Contact />} />

            {/* Protected routes */}
            <Route element={<ProtectedRoute />}>
              <Route path="/power-purchase" element={<PowerPurchase />} />
              <Route path="/service-fee" element={<ServiceFee />} />
            </Route>

            {/* Fallback for undefined routes */}
            <Route path="*" element={<Home />} />
          </Routes>
        </Router>
      </AuthProvider>
    </NativeBaseProvider>
  );
};

export default App;