import React from 'react';
import { useAuth } from '../context/AuthContext';
import { Button, Box, Text } from 'native-base';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = () => {
    login();
    navigate('/power-purchase');  // Redirect to authenticated page after login
  };

  return (
    <Box flex={1} justifyContent="center" alignItems="center">
      <Text fontSize="xl" mb={4}>Please log in to continue</Text>
      <Button onPress={handleLogin}>Login</Button>
    </Box>
  );
};

export default Login;