import React from 'react';
import { Select, Box } from 'native-base';

interface PaymentOptionSelectProps {
  value: string;
  onChange: (value: string) => void;
  options: string[];
}

const PaymentOptionSelect: React.FC<PaymentOptionSelectProps> = ({ value, onChange, options }) => {
  return (
    <Box>
      <Select
        selectedValue={value}
        minWidth="200"
        accessibilityLabel="Select Payment"
        placeholder="Select Payment"
        onValueChange={(itemValue) => onChange(itemValue)}
      >
        {options.map((option) => (
          <Select.Item label={option} value={option} key={option} />
        ))}
      </Select>
    </Box>
  );
};

export default PaymentOptionSelect;