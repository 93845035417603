import React from 'react';
import { Box } from 'native-base';
import PaymentForm from '../components/PaymentForm';

const ServiceFee: React.FC = () => {
  const handleFormSubmit = (data: any) => {
    console.log('Service Fee Data:', data);
  };

  return (
    <Box flex={1} justifyContent="center" alignItems="center">
      <PaymentForm
        options={['Service Fee']}
        onSubmit={handleFormSubmit}
      />
    </Box>
  );
};

export default ServiceFee;