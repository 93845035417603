import React from 'react';
import { Button } from 'native-base';

interface PaymentButtonProps {
  onPress: () => void;
  label: string;
}

const PaymentButton: React.FC<PaymentButtonProps> = ({ onPress, label }) => {
  return (
    <Button onPress={onPress} mt={4} colorScheme="primary">
      {label}
    </Button>
  );
};

export default PaymentButton;