import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavBarContainer = styled.nav`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
`;

const NavLink = styled(Link)`
  margin: 0 15px;
  text-decoration: none;
  color: #2980b9;
  font-size: 1.2rem;

  &:hover {
    text-decoration: underline;
  }
`;

const NavBar: React.FC = () => {
  return (
    <NavBarContainer>
      <NavLink to="/">Home</NavLink>
      <NavLink to="/about">About</NavLink>
      <NavLink to="/contact">Contact</NavLink>
    </NavBarContainer>
  );
};

export default NavBar;