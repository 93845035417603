import React, { useState } from 'react';
import { VStack, Box } from 'native-base';
import PaymentOptionSelect from './PaymentOptionSelect';
import InputField from './InputField';
import RadioSelect from './RadioSelect';
import PaymentButton from './PaymentButton';

interface PaymentFormProps {
  options: string[];
  onSubmit: (data: any) => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ options, onSubmit }) => {
  const [paymentType, setPaymentType] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [purchaseAmount, setPurchaseAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');

  const handleSubmit = () => {
    const formData = {
      paymentType,
      houseNumber,
      purchaseAmount,
      paymentMethod,
    };
    onSubmit(formData);
  };

  return (
    <Box bg="red.600" p={5} borderRadius={10}>
      <VStack space={4}>
        <PaymentOptionSelect
          value={paymentType}
          onChange={setPaymentType}
          options={options}
        />
        <InputField
          label="House Number"
          placeholder="Enter your house number"
          value={houseNumber}
          onChange={setHouseNumber}
        />
        <InputField
          label="Purchase Amount"
          placeholder="Enter amount"
          value={purchaseAmount}
          onChange={setPurchaseAmount}
        />
        <RadioSelect
          value={paymentMethod}
          onChange={setPaymentMethod}
          options={['Wema Bank', 'Paystack']}
        />
        <PaymentButton onPress={handleSubmit} label="Pay" />
      </VStack>
    </Box>
  );
};

export default PaymentForm;