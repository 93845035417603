import React from 'react';
import { Radio, Stack } from 'native-base';

interface RadioSelectProps {
  options: string[];
  value: string;
  onChange: (value: string) => void;
}

const RadioSelect: React.FC<RadioSelectProps> = ({ options, value, onChange }) => {
  return (
    <Radio.Group
      name="paymentMethod"
      value={value}
      onChange={(nextValue) => {
        onChange(nextValue);
      }}
    >
      <Stack direction="row">
        {options.map((option) => (
          <Radio value={option} key={option} my={1}>
            {option}
          </Radio>
        ))}
      </Stack>
    </Radio.Group>
  );
};

export default RadioSelect;