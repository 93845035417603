import React from 'react';
import { Input, FormControl } from 'native-base';

interface InputFieldProps {
  label: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
}

const InputField: React.FC<InputFieldProps> = ({ label, placeholder, value, onChange }) => {
  return (
    <FormControl>
      <FormControl.Label>{label}</FormControl.Label>
      <Input
        placeholder={placeholder}
        value={value}
        onChangeText={(text: string) => onChange(text)}
      />
    </FormControl>
  );
};

export default InputField;